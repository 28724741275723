<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import axios from "axios";
import appConfig from "../../../../src/app.config";

export default {
  locales: {
    pt: {
      Order: "Pedido",
      "Payment Method": "Forma de Pagamento",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Switch plans": "Trocar de Plano",
      "Current choice": "Escolha Atual",
      Choose: "Escolher",
      "Plan name": "Nome do Plano",
      Plans: "Planos",

      "Awaiting payment": "Aguardando pagamento",
      "Payment confirmed": "Pagamento confirmado",

      Address: "Endereço",
      "Send exactly value to make payment":
        "Envie exatamente o valor para efetuar o pagamento",

      "The order was not found.": "O pedido não foi encontrado.",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      Order: "Pedido",
      "Payment Method": "Forma de Pago",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Switch plans": "Trocar de Plano",
      "Current choice": "Escolha Atual",
      Choose: "Escolher",
      "Plan name": "Nome do Plano",
      Plans: "Planos",

      "Awaiting payment": "En espera de pago",
      "Payment confirmed": "Pago confirmado",

      Address: "Dirección",
      "Send exactly value to make payment":
        "Enviar exactamente el valor de hacer el pago",

      "The order was not found.": "O pedido não foi encontrado.",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      plans: null,

      order: {
        id: this.$route.params.id,
        date: null,

        subtotal: "0.00",
        discount: "0.00",
        shipping: {
          method: "",
          value: "0.00",
          address: {
            zipcode: "",
            address: "",
            number: "",
            complement: "",
            district: "",
            city: "",
            state: "",
          },
        },
        payment: {
          methods: [],
          list: [],
        },

        total: "0.00",
        topay: "0.00",

        items: null,
        status: null,
      },

      document: {
        loading: false,
        status: false,
        id: null,
        title: null,
        file: null,
      },

      file: null,

      proof: {
        add: false,
        loading: false,
        errored: false,
        status: false,
        list: null,
      },
    };
  },
  methods: {
    getOrder() {
      if (this.order.id) {
        api
          .get("store/orders/" + this.order.id)
          .then((response) => {
            if (response.data.status == "success") {
              this.order = response.data.order;

              this.order.topay = parseFloat(response.data.order.total);
              response.data.order.payment.list.forEach((e) => {
                if (e.value > 0) {
                  this.order.topay -= parseFloat(e.value);
                }
              });

              this.loading = false;
            } else {
              this.empty = true;
              this.message = response.data.message;
            }
          })
          .catch((error) => {
            this.errored = error;
          });
      }
    },
    getFile: function () {
      this.proof.loading = true;

      api
        .get("store/orders/file/" + this.order.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.proof.list = response.data.files;
          } else {
            this.proof.list = null;
          }
        })
        .catch((error) => {
          this.proof.errored = error;
          this.proof.loading = false;
        })
        .finally(() => {
          this.proof.loading = false;
        });
    },
    setFile() {
      this.document.loading = true;

      let formData = new FormData();

      formData.append("file", this.file);

      var config = {
        headers: {
          "x-auth-token": localStorage.token,
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .post(
          appConfig.apiUrl + "store/orders/file/" + this.order.id,
          formData,
          config
        )
        .then((response) => {
          if (response.data.status == "success") {
            this.getFile();
            this.$noty.success(response.data.message);
            this.document.status = false;
          } else {
            this.$noty.error(response.data.message);
            this.document.status = false;
          }
          this.document.loading = false;
        });
    },
    onFileSelected() {
      this.file = this.$refs.file[0].files[0];
    },
  },
  mounted() {
    this.getOrder();
    this.getFile();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Order") }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="empty">{{ t("No records found.") }}</div>
            <div v-else class="p-2">
              <div class="d-flex">
                <h4 class="mb-0 font-size-15 flex-fill">#{{ order.id }}</h4>
                <h4 class="mb-0 font-size-15 flex-fill text-right">
                  {{ order.date }}
                </h4>
              </div>
              <hr />
              <div class="table-responsive">
                <table class="table table-nowrap">
                  <thead>
                    <tr>
                      <th class="border-0 text-uppercase">{{ t("Item") }}</th>
                      <th class="border-0 text-uppercase">{{ t("Price") }}</th>
                      <th class="border-0 text-uppercase">{{ t("Qty") }}</th>
                      <th class="border-0 text-uppercase">{{ t("Total") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(td, index) in order.items" :key="index">
                      <td>{{ td.name }}</td>
                      <td>{{ td.price | currency }}</td>
                      <td>{{ td.quantity }}</td>
                      <td>{{ td.subtotal | currency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-5">
                <div class="col-lg-6 mb-4">
                  <div v-if="order.shipping.address.zipcode">
                    <h3
                      v-if="order.type == 'receive'"
                      class="font-size-16 mb-3"
                    >
                      {{ t("Endereço de entrega") }}
                    </h3>
                    <h3 v-else class="font-size-16 mb-3">
                      {{ t("Endereço de retirada") }}
                    </h3>
                    {{ order.shipping.address.address }},
                    {{ order.shipping.address.number }},
                    <span v-if="order.shipping.address.complement"
                      >{{ order.shipping.address.complement }},
                    </span>
                    {{ order.shipping.address.district }}<br />
                    {{ order.shipping.address.city }}/{{
                      order.shipping.address.state
                    }},
                    {{ order.shipping.address.zipcode }}
                  </div>
                  <a
                    v-if="
                      order.shipping.tracking && order.shipping.tracking.code
                    "
                    target="_blank"
                    :href="order.shipping.tracking.url"
                    class="btn btn-default btn-sm mt-1 p-2 align-middle"
                  >
                    <i
                      class="bx bxs-truck font-size-17 text-white mr-1 align-top"
                    ></i>
                    Rastrear pedido
                  </a>
                </div>
                <div class="col-lg-6 mb-4">
                  <h3 class="font-size-16 mb-3">
                    {{ t("Detalhes do pagamento") }}
                  </h3>
                  <div class="table-responsive">
                    <table class="table table-nowrap table-borderless">
                      <tbody>
                        <tr v-if="order.subtotal != order.total">
                          <td class="px-0 py-1">{{ t("SUBTOTAL") }}</td>
                          <td class="px-0 py-1 text-right">
                            {{ order.subtotal | currency }}
                          </td>
                        </tr>
                        <tr v-if="order.shipping.value != '0.00'">
                          <td class="px-0 py-1">
                            {{ t("FRETE") }} — {{ order.shipping.method }}
                          </td>
                          <td class="px-0 py-1 text-right">
                            + {{ order.shipping.value | currency }}
                          </td>
                        </tr>
                        <tr v-if="order.discount != '0.00'">
                          <td class="px-0 py-1">{{ t("DESCONTO") }}</td>
                          <td class="px-0 py-1 text-right">
                            - {{ order.discount | currency }}
                          </td>
                        </tr>
                        <tr>
                          <td class="px-0 py-1 font-weight-bold">
                            {{ t("TOTAL") }}
                          </td>
                          <td class="px-0 py-1 font-weight-bold text-right">
                            {{ order.total | currency }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h3 class="font-size-16 mb-2">{{ t("Status") }}</h3>
              <div v-for="(td, index) in order.status" :key="index">
                {{ td.date }} — {{ t(td.status) }}
              </div>
              <b-tabs
                v-if="order.payment.status === 'pending'"
                justified
                nav-class="nav-tabs-custom mt-5"
                content-class="p-3 text-muted"
              >
                <b-tab
                  v-for="(pay, method) in order.payment.methods"
                  :key="method"
                >
                  <template v-slot:title>{{ pay.name }}</template>
                  <div v-if="method === 'usdt'" class="text-center">
                    <img alt="" :src="pay.qrcode" /><br />
                    <p>
                      {{ t("Send exactly value to make payment") }}
                      <small v-if="pay.expiration"
                        ><br />Expiration in {{ pay.expiration }}</small
                      >
                    </p>
                    <p class="font-size-16 mt-0 mb-0">
                      <small class="text-uppercase">{{ t("Value") }}</small
                      ><br />
                      <strong>{{ pay.total }}</strong>
                      <button
                        class="btn btn-link p-0 ml-2"
                        v-clipboard:copy="pay.total"
                      >
                        <i class="bx bx-copy font-size-18 text-success"></i>
                      </button>
                    </p>
                    <p class="font-size-16 mt-2 mb-0">
                      <small class="text-uppercase">{{ t("Address") }}</small
                      ><br />
                      <strong>{{ pay.address }}</strong>
                      <button
                        class="btn btn-link p-0 ml-2"
                        v-clipboard:copy="pay.address"
                      >
                        <i class="bx bx-copy font-size-18 text-success"></i>
                      </button>
                    </p>
                  </div>
                  <div v-else class="text-center">
                    <img
                      class="mt-1 mb-4"
                      height="50px"
                      src="@/assets/images/payments/logo-mercadopago.svg"
                    /><br />
                    <a
                      target="_blank"
                      class="btn btn-default btn-lg"
                      :href="pay.url"
                      >{{ t("Clique aqui para pagar") }}</a
                    >
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
